import firebase from '../lib/firebase';
import axios from 'axios';
import { APP_BASE_URL } from '../services/config';

const authInstance = axios.create({
  baseURL: APP_BASE_URL,
});

authInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await firebase.auth().currentUser.getIdToken();
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      id: firebase.auth().currentUser.uid,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { authInstance };
