import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseConfig } from '../config';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (process.env.NODE_ENV === 'development') {
    const auth = firebase.auth();
    auth.useEmulator('http://localhost:9099');
  }
}

export default firebase;
