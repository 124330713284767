import React, { createContext, useReducer } from 'react';
import { useParams } from 'react-router';
import { get } from '../services/BoatService';

const features = [
  {
    name: 'Features',
    values: [
      { selected: true, description: 'Air conditioning' },
      { selected: true, description: 'Anchor' },
      { selected: true, description: 'Bathroom' },
      { selected: true, description: 'Bluetooth Audio' },
      { selected: true, description: 'Cooler/Ice Chest' },
      { selected: true, description: 'Deck Shower' },
      { selected: true, description: 'Fish Finder' },
      { selected: true, description: 'Grill' },
      { selected: true, description: 'Livewell/Baitwell' },
      { selected: true, description: 'Refrigerator' },
      { selected: true, description: 'Rod Holders' },
      { selected: true, description: 'Shower' },
      { selected: true, description: 'Stereo' },
      { selected: true, description: 'Stereo Aux Input' },
      { selected: true, description: 'Swim Ladder' },
      { selected: true, description: 'Wifi' },
    ],
  },
  {
    name: 'Extras',
    values: [
      { selected: true, description: "Children's Life Jackets" },
      { selected: true, description: 'Fishing Gear' },
      { selected: true, description: 'Floating Island' },
      { selected: true, description: 'Floating Mat' },
      { selected: true, description: 'Inflatable Toys' },
      { selected: true, description: 'Jet Ski' },
      { selected: true, description: 'Kayaks' },
      { selected: true, description: 'Paddleboards' },
      { selected: true, description: 'SeaBob' },
      { selected: true, description: 'Snorkeling Gear' },
      { selected: true, description: 'Tender' },
      { selected: true, description: 'Wakeboard' },
      { selected: true, description: 'Waterskis' },
    ],
  },
  {
    name: 'Navigation',
    values: [
      { selected: true, description: 'Bow Thruster' },
      { selected: true, description: 'Depth Finder' },
      { selected: true, description: 'GPS' },
      { selected: true, description: 'Radar' },
      { selected: true, description: 'Sonar' },
      { selected: true, description: 'Vhf Radio' },
    ],
  },
];

export const group_steps = [
  {
    name: 'Boat details',
    steps: [
      {
        route: 'insurance',
        completed: false,
      },
      {
        route: 'location',
        completed: false,
      },
      { route: 'title', name: 'Listing', completed: false },
      { route: 'cancelation-policy', name: 'Policy', completed: false },
      { route: 'allowed', name: 'Allowed', completed: false },
      { route: 'features', name: 'Features', completed: false },
      { route: 'specs', name: 'Boat', completed: false },
    ],
  },
  {
    name: 'Availability',
    steps: [
      {
        route: 'availability',
        completed: false,
      },
    ],
  },
  {
    name: 'Pricing',
    steps: [
      {
        route: 'pricing',
        completed: false,
      },
    ],
  },
  {
    name: 'Review',
    steps: [
      {
        route: 'review',
        completed: false,
      },
    ],
  },
];

export const steps = [].concat.apply(
  [],
  group_steps.map((item) => item.steps)
);

const locationInit = {
  // init
  marinaName: '',
  locationType: '',
  slipNumber: '',
  country: 'United States',
  address: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  latitude: null,
  longitude: null,
};

const listingInit = { title: '', description: '', images: [] };

const specsInit = {
  year: 0,
  make: '',
  model: '',
  type: '',
  category: '',
  capacity: '',
  cabins: '',
  draft: '',
  heigh: '',
  length: '',
  name: '',
  restroom: '',
  speed: '',
};

export const boatInitialState = {
  // 1 - Insurance
  insurance: {
    type: '',
  },
  // 2 - Location
  location: locationInit,
  // 3 - Listing
  listing: listingInit,
  images: [],
  // 4 - Policy
  policy: null,
  // 5 - Allowed
  allowed: [],
  // 6 - Feature
  features: features,
  // 7 - Spec
  specs: specsInit,
  // 8 - Availability
  availability: null,
  // pricing
  pricing: [
    { value: '2', selected: true, price: '' },
    { value: '4', selected: false, price: '' },
    { value: '6', selected: false, price: '' },
    { value: '8', selected: false, price: '' },
  ],
  advancedNotice: 1,
  status: false,
};

export const initialState = { boat: boatInitialState, backStep: undefined, current: undefined, nextStep: undefined, steps: steps, validate: (step, completed) => {}, init: (step) => {}, set: (boat) => {}, refresh: () => {}, flag: true };

const StepsContext = createContext({ ...initialState });

const reducer = (state, action) => {
  if (action.type === 'VALIDATE') {
    state.current.completed = action.payload.completed;
    const current = state.current;
    current.completed = action.payload.completed;
    return { ...state, current };
  }
  if (action.type === 'INIT') {
    const backStep = steps[action.payload.step - 1];
    const current = steps[action.payload.step];
    const nextStep = steps[action.payload.step + 1];
    return { ...state, backStep, current, nextStep };
  }

  if (action.type === 'REFRESH') {
    return { ...state, ...!state.flag };
  }

  if (action.type === 'API') {
    console.log('PAYLOAD==>>', { ...state.boat, ...action.payload.boat });
    return { ...state, boat: action.payload.boat };
  }

  return state;
};

export const StepsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id } = useParams();

  React.useEffect(() => {
    get(id).then((resp) => {
      dispatch({ type: 'API', payload: { boat: resp.data } });
    });
  }, [id]);

  const init = (step) => {
    dispatch({
      type: 'INIT',
      payload: {
        step: step,
      },
    });
  };

  const validate = (completed) => {
    dispatch({
      type: 'VALIDATE',
      payload: {
        completed: completed,
      },
    });
  };

  const refresh = () => {
    dispatch({
      type: 'REFRESH',
      payload: {},
    });
  };

  return <StepsContext.Provider value={{ ...state, init, validate, refresh }}>{children}</StepsContext.Provider>;
};

export default StepsContext;
