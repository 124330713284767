/* This example requires Tailwind CSS v2.0+ */
import routes from './routes';
import { useRoutes } from 'react-router';
import useAuth from './hooks/useAuth';

function App() {
  const content = useRoutes(routes);
  const auth = useAuth();

  return <>{auth.isInitialized ? <>{content}</> : <div>Splash</div>}</>;
}

export default App;
