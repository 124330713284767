import React from 'react';
import { Link } from 'react-router-dom';
import { Outlet, useNavigate, useParams } from 'react-router';
import useSteps from '../../hooks/useSteps';
import Indicator from '../../pages/Steps/_indicator';
import { update } from '../../services/BoatService';

export default function ListBoatLayout() {
  const stepHook = useSteps();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleUpdate = () => {
    stepHook.steps.forEach((element) => {
      element.completed = false;
    });
    update(stepHook.boat, id)
      .then((result) => {
        navigate('/owner/boats');
      })
      .catch((err) => {
        console.log('ERR!');
      });
  };

  const onComplete = () => {
    stepHook.boat.status = true;
    handleUpdate();
  };

  const onSave = () => {
    handleUpdate();
  };

  return (
    <>
      {/* <Example /> */}
      <div className="fixed top-0 left-0 right-0 bg-gray-800 h-16">
        {/* <div className="text-white text-xs">{JSON.stringify(stepHook.boat.specs.make)}</div> */}
        <div className="flex justify-end max-w-7xl mx-auto py-3 px-6 lg:px-8">
          <button
            onClick={onSave}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
            Save & Exit
          </button>
        </div>
      </div>
      <main>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 mt-20">
          <Indicator />
          <Outlet />
        </div>
      </main>
      <div className="h-20"></div>
      <div className="fixed bottom-0 left-0 right-0 h-30 bg-white shadow-2xl">
        <div className="flex justify-between max-w-7xl mx-auto py-6 px-6 lg:px-8 filter drop-shadow-xlg">
          {stepHook.backStep ? (
            <>
              <Link
                to={stepHook.backStep.route}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
                Back
              </Link>
            </>
          ) : (
            <></>
          )}

          <div></div>

          {/* <div className="text-white">{JSON.stringify(stepHook.boat)}</div> */}
          {stepHook.nextStep ? (
            <Link
              to={stepHook.current.completed ? stepHook.nextStep.route : stepHook.current.route}
              className={
                !stepHook.current.completed
                  ? 'ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-300 hover:bg-gray-300 cursor-default'
                  : 'ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
              }

              // className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
            >
              Continue
            </Link>
          ) : !stepHook.boat.status ? (
            <button type="button" onClick={onComplete} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Complete
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}
