import React from 'react';
import useSteps from '../../hooks/useSteps';
import Header from './_header';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Pricing() {
  const stepsHooks = useSteps();

  React.useEffect(() => {
    stepsHooks.init(8);
    stepsHooks.validate(true);
  }, [stepsHooks.boat.pricing]);

  const onHandleChange = (event) => {
    stepsHooks.boat.pricing[event.target.id].price = event.target.value;
    stepsHooks.refresh();
  };

  const onHandleCheckBoxChange = (event) => {
    stepsHooks.boat.pricing[event.target.id].selected = !stepsHooks.boat.pricing[event.target.id].selected;
    stepsHooks.refresh();
  };

  return (
    <div>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <Header
            title="Now, add your prices."
            description="Some owners start off with a lower price and raise it after earning positive reviews. You can always change your price in response to demand. For example, a higher price may help you earn more during busy travel seasons while a lower price may get you more rentals when it’s slow."
          />

          <div className="mt-5 md:mt-0 md:col-span-2 md: ml-2">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900"></h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Please select your hours rate.</p>
              </div>

              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                {stepsHooks.boat.pricing.map((item, index) => (
                  <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-6 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                    <div className="relative flex items-start mb-3 sm:mb-0">
                      <div className="flex items-center h-5">
                        <input value={item.selected} checked={item.selected} onChange={onHandleCheckBoxChange} id={index} aria-describedby="comments-description" name={index} type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="comments" className="font-medium text-gray-700">
                          {item.value} hours
                        </label>
                      </div>
                    </div>
                    <div className="mt-0 sm:mt-0 sm:col-span-1">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">$</span>
                        <input
                          disabled={!item.selected}
                          type="text"
                          name={index}
                          id={index}
                          value={item.price}
                          onChange={onHandleChange}
                          className={classNames(item.selected ? '' : 'text-gray-400', 'flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300')}
                        />
                      </div>
                    </div>
                    {item.price && item.selected ? (
                      <div className="mt-3 sm:mt-0 sm:col-span-1">
                        <div className="flex">
                          <span className="mr-3 text-xs text-gray-500">Total:</span>
                          <span className="text-xs">${item.price}</span>
                        </div>
                        <div className="flex">
                          <span className="mr-3 text-xs text-gray-500">You earn:</span>
                          <span className="text-xs text-green-700 font-semibold">${item.price - item.price / 10}</span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
