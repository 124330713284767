import { Suspense, lazy } from 'react';
import ListBoatLayout from './components/list/ListBoatLayout';
import { StepsProvider } from './contexts/StepsContext';
import Pricing from './pages/Steps/10-Pricing';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback="...">
      <Component {...props} />
    </Suspense>
  );

// layouts
const MainLayout = Loadable(lazy(() => import('./components/MainLayout')));
const OwnerLayout = Loadable(lazy(() => import('./components/owner/OwnerLayout')));

// pages
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')));
const BookingsList = Loadable(lazy(() => import('./pages/BookingsList')));
const Payment = Loadable(lazy(() => import('./pages/Payment')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const BoatsList = Loadable(lazy(() => import('./pages/BoatsList')));
const Messages = Loadable(lazy(() => import('./pages/Messages')));
const BoatDetails = Loadable(lazy(() => import('./pages/boat/BoatDetails')));

// Steps
const AdvanceNotice = Loadable(lazy(() => import('./pages/Steps/9-AdvanceNotice')));
const Allowed = Loadable(lazy(() => import('./pages/Steps/5-Allowed')));
const Availability = Loadable(lazy(() => import('./pages/Steps/8-Availability')));
const CancelationPolicy = Loadable(lazy(() => import('./pages/Steps/4-CancelationPolicy')));
const Features = Loadable(lazy(() => import('./pages/Steps/6-Features')));
const Insurance = Loadable(lazy(() => import('./pages/Steps/1-Insurance')));
const Location = Loadable(lazy(() => import('./pages/Steps/2-Location')));
const Specs = Loadable(lazy(() => import('./pages/Steps/7-Specs')));
const Title = Loadable(lazy(() => import('./pages/Steps/3-Title')));
const Review = Loadable(lazy(() => import('./pages/Steps/20-Review')));

const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    ),
    children: [{ path: '/', element: <Home /> }],
  },
  {
    path: '/owner',
    element: (
      <AuthGuard>
        <OwnerLayout />
      </AuthGuard>
    ),
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'bookings', element: <BookingsList /> },
      { path: 'payment', element: <Payment /> },
      {
        path: 'boats',
        element: <BoatsList />,
      },
      { path: 'messages', element: <Messages /> },
    ],
  },
  {
    path: '/owner/list-boat/:id',
    element: (
      <StepsProvider>
        <ListBoatLayout />
      </StepsProvider>
    ),
    children: [
      //{ path: 'advance-notice', element: <AdvanceNotice /> },
      { path: 'allowed', element: <Allowed /> },
      { path: 'availability', element: <Availability /> },
      { path: 'cancelation-policy', element: <CancelationPolicy /> },
      { path: 'features', element: <Features /> },
      { path: 'insurance', element: <Insurance /> },
      { path: 'location', element: <Location /> },
      { path: 'specs', element: <Specs /> },
      { path: 'title', element: <Title /> },
      { path: 'review', element: <Review /> },
      { path: 'pricing', element: <Pricing /> },
    ],
  },
  {
    path: '/boat/:id',
    element: <BoatDetails />,
  },
];

export default routes;
