import React from 'react';
import { group_steps } from '../../contexts/StepsContext';
import { Link } from 'react-router-dom';
import useSteps from '../../hooks/useSteps';
import { Divider } from './_divider';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Indicator() {
  const stepHook = useSteps();
  const current = stepHook.current;
  return (
    <>
      <div className="sm:py-4">
        <nav aria-label="Progress">
          <nav aria-label="Progress">
            <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
              {group_steps.map((item, index) => (
                <div key={index} className="group pl-0 py-2 flex flex-col md:flex-1">
                  <ProgressBar width={(100 / item.steps.length) * item.steps.filter((item) => item.completed).length} />

                  {item.steps.filter((item) => current && current.route === item.route).length > 0 ? (
                    <Link to={item.steps[0].route} className="flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 pl-2">
                      <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">STEP {index + 1}</span>
                      <span className="text-sm font-medium">{item.name}</span>
                    </Link>
                  ) : (
                    <div className="flex flex-col border-l-4  md:pl-0 md:pt-4 md:pb-0 md:border-l-0 pl-2">
                      <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">STEP {index + 1}</span>
                      <span className="text-sm font-medium">{item.name}</span>
                    </div>
                  )}
                </div>
              ))}
              {/* {steps.map((step, index) => (
                <li key={step.name} className="md:flex-1">
                  {step.completed ? (
                    <Link to={step.route} className="group pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                      <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">STEP {index + 1}</span>
                      <span className="text-sm font-medium">{step.name}</span>
                    </Link>
                  ) : current && current.step === index ? (
                    <Link to={step.route} className="pl-4 py-2 flex flex-col border-l-4 border-indigo-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
                      <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase">STEP {index + 1}</span>
                      <span className="text-sm font-medium">{step.name}</span>
                    </Link>
                  ) : (
                    <a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                      <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">STEP {index + 1}</span>
                      <span className="text-sm font-medium">{step.name}</span>
                    </a>
                  )}
                </li>
              ))} */}
            </ol>
          </nav>
        </nav>
        {/* <nav aria-label="Progress" className="sr-only">
          <ol role="list" className="flex items-center">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
                {step.completed ? (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-indigo-600" />
                    </div>
                    <Link to={step.route} className="relative w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-900">
                      <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </Link>
                  </>
                ) : current && current.step === stepIdx ? (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <Link to={step.route} className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full" aria-current="step">
                      <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </Link>
                  </>
                ) : (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <a href="#" className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav> */}
        <Divider />
      </div>
    </>
  );
}
function ProgressBar(props) {
  return (
    <div className="bg-gray-200 rounded-full overflow-hidden invisible md:visible">
      <div className="h-1 sm:bg-indigo-600" style={{ width: props.width + '%' }} />
    </div>
  );
}
