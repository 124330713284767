let APP_BASE_URL = '';
let APP_PUBLIC_BASE_URL = '';
if (process.env.NODE_ENV === 'development') {
  APP_BASE_URL = 'http://localhost:5001/on-my-yacht/us-central1/api/v1';
  APP_PUBLIC_BASE_URL = 'http://localhost:5001/on-my-yacht/us-central1/public';
} else {
  APP_BASE_URL = 'https://us-central1-on-my-yacht.cloudfunctions.net/api/v1';
  APP_PUBLIC_BASE_URL = 'https://us-central1-on-my-yacht.cloudfunctions.net/public';
}

export { APP_BASE_URL, APP_PUBLIC_BASE_URL };
