import { APP_BASE_URL, APP_PUBLIC_BASE_URL } from './config';
import { authInstance } from '../api/axios';
import axios from 'axios';

export const getAll = () => {
  return authInstance
    .get(`${APP_BASE_URL}/boats`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export const get = (id) => {
  return authInstance
    .get(`${APP_BASE_URL}/boats/${id}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export const getDetails = async (id) => {
  try {
    const result = await axios.get(`${APP_PUBLIC_BASE_URL}/boat/${id}`);
    return result;
  } catch (err) {
    return err;
  }
};

export const update = (boat, id) => {
  return authInstance
    .put(`${APP_BASE_URL}/boats/${id}`, boat)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export const getStarted = (model) => {
  return authInstance
    .post(`${APP_BASE_URL}/boats/get-started`, model)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export const getBookingsByDate = async () => {
  return authInstance
    .get(`${APP_BASE_URL}/bookings`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
};
