import React from 'react';

export default function Header(props) {
  return (
    <div className="md:col-span-1">
      <div className="sm:px-0">
        <h3 className="text-2xl font-extrabold text-gray-900">{props.title}</h3>
        <p className="mt-1 text-sm text-gray-600">{props.description}</p>
      </div>
    </div>
  );
}
