// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: REACT_APP_MEASUREMENT_ID,
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyC3bnoUPZH3o04Fmu-o1BIGfe-mfxd3a6o',
  authDomain: 'on-my-yacht.firebaseapp.com',
  databaseURL: 'https://on-my-yacht-default-rtdb.firebaseio.com',
  projectId: 'on-my-yacht',
  storageBucket: 'on-my-yacht.appspot.com',
  messagingSenderId: '854271251105',
  appId: '1:854271251105:web:019dbae4e9d2f5aad496e0',
  measurementId: 'G-SW645G50VR',
};
